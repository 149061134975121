import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from '../components/Layout';

const Gallery = () => {
  const {
    markdownRemark: {
      frontmatter: { title, subtitle, heading },
      html,
    },
  } = useStaticQuery(graphql`
    query GalleryQuery {
      markdownRemark(frontmatter: { pageName: { eq: "gallery" } }) {
        frontmatter {
          title
          subtitle
          heading
        }
        html
      }
    }
  `);

  return (
    <Layout fullMenu>
      <section id="wrapper">
        <header>
          <div className="inner">
            <h2>{title}</h2>
            <p>{subtitle}</p>
          </div>
        </header>

        <div className="wrapper">
          <div className="inner">
            <section>
              {/* <h3 className="major">Image</h3> */}
              <h3 className="major">{heading}</h3>

              <div className="box alt">
                <div className="galleryImages">
                  <div
                    className="row gtr-uniform"
                    dangerouslySetInnerHTML={{ __html: html }}
                  />
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Gallery;
